import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomPipesModule } from '../custom-pipes.module';
import { HeaderComponent } from './header.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CogButtonComponent } from './cog-button/cog-button.component';
import { FeedbackModalComponent } from './-components-/feedback-modal/feedback-modal.component';
import { ProfileButtonContainerComponent } from './profile-buttons/profile-button-container.component';
import { ProfileButtonLoggedinComponent } from './profile-buttons/profile-button-loggedin/profile-button-loggedin.component';
import { ProfileButtonLoggedoutComponent } from './profile-buttons/profile-button-loggedout/profile-button-loggedout.component';
import { NotificationListComponent } from './notification-list/notification-list.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgLetModule } from 'ng-let';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { DataViewModule } from 'primeng/dataview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageModule } from 'primeng/message';
import { CustomCommonModule } from '../custom-common.module';
import { SystemAnnouncementService } from 'src/app/system-announcements/system-announcements.service';
import { HeaderService } from './header.service';

@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbComponent,
    CogButtonComponent,
    FeedbackModalComponent,
    ProfileButtonContainerComponent,
    ProfileButtonLoggedinComponent,
    ProfileButtonLoggedoutComponent,
    NotificationListComponent
  ],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    NgLetModule,
    FormsModule,
    BreadcrumbModule,
    DialogModule,
    ReactiveFormsModule,
    MenubarModule,
    MenuModule,
    MessageModule,
    SidebarModule,
    DataViewModule,
    ButtonModule,
    CustomPipesModule,
    InputSwitchModule,
    CustomCommonModule
  ],
  providers: [HeaderService, SystemAnnouncementService]
})
export class HeaderModule {}
