<ng-container *ngIf="shouldDisplay">
  <div class="content-section implementation navbar">
    <div class="p-card">
      <p-menubar [model]="(items$ | async) ?? []" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <ng-template pTemplate="start">
          <a [routerLink]="['/']">
            <img [src]="logoSrc" height="40" class="mr-2" />
          </a>
        </ng-template>

        <ng-template pTemplate="end" class="rightMenu">
          <p-inputSwitch
            [(ngModel)]="compactMode"
            (onChange)="setCompactMode()"
            pTooltip="Compact Mode"
            tooltipPosition="bottom"
            class="mr-3"
          ></p-inputSwitch>

          <button
            pButton
            type="button"
            icon="pi pi-bell"
            (click)="toggleNotificationsSidebar()"
            class="notification-button p-button-rounded p-button-outlined mr-3"
          >
            <span class="" notification-badge>{{ notifications.length }}</span>
          </button>

          <app-cog-button></app-cog-button>
          <app-profile-button-container></app-profile-button-container>
        </ng-template>
      </p-menubar>
      <div *ngIf="showNotice" class="additional-msg">
        You are currently accessing application as <b class="mr-1 ml-1">CA Viewer</b>, hence your functionality is limited. To avail additional
        features, please enroll as <b class="mr-1 ml-1"><u>Tax Manager</u></b> by contacting eSupport.
      </div>

      <div *ngIf="showAnnouncement" class="announcement-msg">
        <span class="w-full">
          <strong>
            Annoucement : “ <a href="announcements"> {{ latestAnnnouncementTitle }} </a> ” made...
          </strong>
          <i class="pi pi-megaphone ml-1 announcement-icon"></i>
        </span>
        <div class="announcement-dismiss" (click)="dismissAnnouncement()">
          <i title="Dismiss" class="pi pi-times dismiss-icon"></i>
        </div>
      </div>
    </div>
  </div>
  <div [class]="showNotice ? 'header-content add-notice' : 'header-content'">
    <app-breadcrumb></app-breadcrumb>
  </div>
</ng-container>

<app-notification-list
  *ngIf="showNotificationsSideBar"
  (notificationDismissed)="dismissNotification($event)"
  (allNotificationDissmissed)="dismissAllNotifications()"
  [notifications]="notifications"
  [closeSideBar]="toggleNotificationsSidebar"
  [sidebarVisible]="showNotificationsSideBar"
></app-notification-list>
